/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Collapse from "react-bootstrap/Collapse";

// Components
import Icon from "../custom-widgets/icon";
import ExternalLinkModal from "../external-modal/external-modal";
import NotificationAlert from "../notifications/notification-alert";

// Helpers
import getSortedFaqDataByCategory from "../../helpers/getSortedFaqDataByCategory";
import isLinkUrlExternal from "../../helpers/isLinkUrlExternal";

// Styles
import "./faq-accordion.bootstrap.scss";

import { useLanguageContext } from "../../contexts/language-context";

const FaqAccordion = (props) => {
  const isSpanish = useLanguageContext();

  // console.log("FaqAccordion props: ", props);

  if (props.faqCategoryNames.length === 0 && props?.faqData === null) {
    throw new Error("Props faqCategoryNames array is empty AND faqData object is null!");
  }

  const [showFaqs, setShowFaqs] = useState(false); // Bool to wait until FAQ data is loaded before showing the markup
  const [faqs, setFaqs] = useState([]); // Array of FAQ Objects
  const [footnotes, setFootnotes] = useState([]); // Array of Footnote Strings (HTML)
  const [expandAll, setExpandAll] = useState(true); // Bool used for "Expand All" or "Collapse All" functionality
  const [collapseClass, setCollapseClass] = useState({}); // Object for each FAQ ID (key) of Collapse State Classes (value)
  const [allCollapsed, setAllCollapsed] = useState({}); // Object for all FAQs set to false, used to reset collapseClass to all collapsed
  const [allExpanded, setAllExpanded] = useState({}); // Object for all FAQs set to true, used to reset collapseClass to all expanded
  const [expandedCount, setExpandedCount] = useState(0); // Int of how many FAQs are currently expanded

  let results = {};
  // if the parent/caller page already fetched the faqData for SEO schema, use that existing faqData
  if (props?.faqData?.sortedFaqs) {
    // console.log("FaqAccordion (old FaqSection) - using existing faqData: ", props.faqData.sortedFaqs.length);
    results.sortedFaqs = [...props.faqData.sortedFaqs];
    results.categoryFootnotes = [...props.faqData.categoryFootnotes];
  }
  // else fetch the FAQ data for the faqCategoryNames
  else {
    // console.log("FaqAccordion (new design) - getting FAQ data for: ", props.faqCategoryNames);
    // NOTE: this helper function uses custom hooks and CANNOT be called inside of useEffect()!
    results = getSortedFaqDataByCategory(props.faqCategoryNames);
  }

  // initialize the FAQs in state (from the results object) **once and only once**.
  if (faqs.length === 0) {
    if (results.sortedFaqs.length > 0) {
      // console.log("Initializing FAQ data with " + results.sortedFaqs.length + " FAQs.");
      // set the FAQs with sorted FAQ data
      setFaqs(results.sortedFaqs);
      // set the FAQ Category Footnotes
      setFootnotes(results.categoryFootnotes);
    } else {
      console.warn("No FAQ data available!");
    }
  } else {
    // console.log("FAQ data already initialized with " + faqs.length + " FAQs.");
  }

  // Initialize all the Collapse Class Objects once all the FAQs are set
  useEffect(() => {
    // console.log("Setting initial collapse/expand with " + faqs.length + " FAQs.");
    let tempExpanded = {};
    let tempCollapsed = {};
    faqs.forEach((faq) => {
      tempExpanded[faq.id] = true;
      tempCollapsed[faq.id] = false;
    });
    setAllCollapsed({ ...tempCollapsed });
    setAllExpanded({ ...tempExpanded });

    // init the collapseClass object with the allCollapsed
    // console.log("Initializing collapseClass with: ", tempCollapsed);
    setCollapseClass({ ...tempCollapsed });
    setShowFaqs(true);
  }, [faqs]);

  // Handle toggling the collapseClass for a specific FAQ
  function updateCollapseState(faqId) {
    const faqCollapseState = collapseClass[faqId];

    // update the expandedCount
    // console.log("expandedCount: " + expandedCount);
    let x = faqCollapseState ? -1 : 1;
    let newExpandedCount = expandedCount + x;
    // console.log("newExpandedCount: " + newExpandedCount);
    setExpandedCount(newExpandedCount);

    // if all FAQs are expanded, toggle expandAll (to "Collapse All")
    if (newExpandedCount >= faqs.length) {
      setExpandAll(false);
    } else {
      setExpandAll(true);
    }

    // update the collapseClass
    const newCollapseClass = { ...collapseClass }; // copy current collapseClass
    newCollapseClass[faqId] = !faqCollapseState; // toggle the collapse state for this FAQ
    setCollapseClass({ ...newCollapseClass }); // set the collapseClass with the updated collapse state
    // console.log("updateCollapseState() newCollapseClass: ", newCollapseClass);
  }

  // Handle toggling "Expand All" / "Collapse All"
  function toggleExpandAll() {
    // console.log(expandAll ? "Expanding All" : "Collapsing All");
    if (expandAll) {
      setExpandedCount(faqs.length);
    } else {
      setExpandedCount(0);
    }
    const newCollapseClass = expandAll ? { ...allExpanded } : { ...allCollapsed };
    // console.log("newCollapseClass: ", newCollapseClass);
    setCollapseClass({ ...newCollapseClass });
    setExpandAll(!expandAll);
  }

  // External Link Modal functionality
  const [showExtLinkModal, setShowExtLinkModal] = useState(false);
  const [extUrl, setExtUrl] = useState("/");
  const handleCloseModal = () => setShowExtLinkModal(false);
  const handleShowModal = (e) => {
    e.preventDefault();
    setExtUrl(e.target.href);
    setShowExtLinkModal(true);
  };
  const extLinkModalData = {
    handleClose: handleCloseModal,
    show: showExtLinkModal,
    url: extUrl
  };

  useEffect(() => {
    // check all links in FAQ Accordion (only) for external links to pop modal
    const faqAccordion = document.getElementById(props.id);
    if (faqAccordion) {
      const anchors = faqAccordion?.getElementsByTagName("a");
      // console.log("FAQ Accordion anchors: ", anchors);
      for (let anchor of anchors) {
        // TODO: why do we care if the typeof href is a string, isn't it always a string?
        if (typeof anchor?.href === "string" && isLinkUrlExternal(anchor.href)) {
          // console.log(`FAQ Accordion: adding external link modal handler for "${anchor.href}"`);
          anchor.addEventListener("click", handleShowModal);
        }
      }
    } else {
      console.warn(`FaqAccordion element "#${props.id}" not found!`);
    }
  }, [showFaqs]);

  // Contact Us notification alert body
  const getAlertBody = () => {
    if (isSpanish) {
      return (
        <>
          <strong>Aún no encuentras lo que buscas?</strong> Comuníquese con su{" "}
          <Link to="/es/sucursales" id="faq-contact-bar-locations-link">
            sucursal local
          </Link>{" "}
          o llámenos las 24 horas del día, los 7 días de la semana al{" "}
          <a href="tel:800-324-9375" id="faq-contact-bar-c3-tel-link">
            800-324-9375
          </a>
          .
        </>
      );
    } else {
      return (
        <>
          <strong>Still can't find what you are looking for?</strong> Contact your{" "}
          <Link to="/locations" id="faq-contact-bar-locations-link">
            local branch
          </Link>{" "}
          or call us 24/7 at{" "}
          <a href="tel:800-324-9375" id="faq-contact-bar-c3-tel-link">
            800-324-9375
          </a>
          .
        </>
      );
    }
  };

  const SECTION_CLASS = props.isBlog && props.sectionClass === "section-padding" ? "pb-4" : props.sectionClass;
  
  return (
    <>
      <section id={props.id} className={`faq-accordion ${props.containerClass} ${SECTION_CLASS}`}>
        <div className="row">
          <div className="col">
            {/* PAGE TITLE */}
            {props.seeMoreFaqsLink && props.pageTitle && <h1 dangerouslySetInnerHTML={{ __html: props.pageTitle }} />}
            {/* FAQs ACCORDION TITLE */}
            {props.seeMoreFaqsLink && props.title && (
              <h2
                id="faq-accordion-title"
                className={props.titleClass}
                dangerouslySetInnerHTML={{ __html: props.title }}
              ></h2>
            )}
          </div>
        </div>
        {/* SEE MORE FAQS & COLLAPSE/EXPAND ALL */}
        <div className="row align-items-baseline">
          <div className={`${props.seeMoreFaqsLink ? "col mb-3" : "col-sm"}`}>
            {props.seeMoreFaqsLink ? (
              <Link id={props.seeMoreFaqsId} className="text-decoration-none text-nowrap" to={props.seeMoreFaqsLink}>
                <span className="h5" dangerouslySetInnerHTML={{ __html: props.seeMoreFaqsText }} />
                <Icon class="ml-2" lib="fas" name={props.seeMoreFaqsIcon} />
              </Link>
            ) : (
              <>
                {props.title && (
                  <h2
                    id="faq-accordion-title"
                    className={props.titleClass}
                    dangerouslySetInnerHTML={{ __html: props.title }}
                  ></h2>
                )}
                {props.pageTitle && <h1 dangerouslySetInnerHTML={{ __html: props.pageTitle }} />}
              </>
            )}
          </div>
          <div className={`text-right mb-3 ${props.seeMoreFaqsLink ? "col-auto" : "col-sm-auto"}`}>
            <div
              class="text-decoration-none text-primary"
              role="button"
              aria-expanded={!expandAll}
              aria-controls={faqs.map((faq) => {
                return "faq-accordion-question-" + faq.id + " ";
              })}
              onClick={(ev) => {
                ev.preventDefault();
                toggleExpandAll();
              }}
              id="faq-accordion-expand-collapse-all-btn"
              tabindex="0"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  toggleExpandAll();
                }
              }}
            >
              <span className="h5">
                {expandAll ? (isSpanish ? "Mostrar" : "Expand") : isSpanish ? "Minimizar" : "Collapse"}{" "}
                {isSpanish ? "Todo" : "All"}
              </span>
              <Icon class="ml-2" lib="fas" name={expandAll ? "chevron-down" : "chevron-up"} />
            </div>
          </div>
        </div>
        {/* ACCORDION */}
        {showFaqs &&
          faqs.map((faq, index) => {
            return (
              <div className={index === faq.length || index === 0 ? "border-y" : "border-bottom"} key={`faq-${index}`}>
                <button
                  id={`faq-accordion-${faq.id}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    updateCollapseState(faq.id);
                  }}
                  className="border-0 w-100 d-flex accordion-toggle py-1 px-4 justify-content-between"
                  aria-controls={`faq-accordion-question-${faq.id}`}
                  aria-expanded={collapseClass[faq.id]}
                  aria-labelledby={`faq-accordion-question-${faq.id}`}
                >
                  <>
                    {/* QUESTION */}
                    <strong
                      id={`faq-accordion-question-${faq.id}`}
                      className="faq-accordion-question text-left mt-1"
                      dangerouslySetInnerHTML={{ __html: faq.Question }}
                    />
                    {faq.FaqIcon && (
                      <Icon class={`mt-1 mr-auto ${faq.FaqIcon.Class}`} name={faq.FaqIcon.Name} lib={faq.FaqIcon.Lib} />
                    )}
                  </>
                  <Icon
                    class="fa-2x text-success ml-2"
                    lib="fal"
                    name={`${collapseClass[faq.id] ? "minus" : "plus"}`}
                  />
                </button>
                {/* ANSWER */}
                <Collapse in={collapseClass[faq.id]}>
                  <div id={`faq-accordion-answer-${faq.id}`}>
                    {faq.Answer && (
                      <div className="faq-answer-text pt-2 px-4" dangerouslySetInnerHTML={{ __html: faq.Answer }} />
                    )}
                    {faq.FaqVideo && (
                      <div className="faq-answer-video ml-4 pl-4 py-3">
                        <div className="row">
                          <iframe
                            src={faq.FaqVideo.VideoURL}
                            frameBorder="0"
                            title={faq.FaqVideo.Title}
                            allow="fullscreen"
                            style={{ minHeight: "300px" }}
                            className="iframe h-100 w-100 col-lg-6"
                          ></iframe>
                        </div>
                        <div className="row mt-3 mt-xl-4">
                          <a
                            href="/support/helpful-tips"
                            id={`faq-accordion-${faq.id}-video-link`}
                            className="text-decoration-none"
                          >
                            <Icon lib="fas" class="fa-lg ml-3 mr-2" name="video" />
                            See more videos
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>
            );
          })}
        {/* CONTACT BAR */}
        {props.showContactBar && (
          <NotificationAlert type="primary" class="mt-3 mb-0" id="faq-contact-us-alert" bodyContent={getAlertBody()} />
        )}
        {/* FOOTNOTES */}
        {footnotes && footnotes.length > 0 && (
          <div className="container text-muted text-sm my-3" id="faq-footnotes-section">
            {footnotes.map((footnote, index) => (
              <div
                key={`footnote-` + index}
                id={`faq-footnote-${footnote.id}`}
                dangerouslySetInnerHTML={{ __html: footnote }}
              />
            ))}
          </div>
        )}
      </section>
      {/* EXTERNAL LINK MODAL */}
      {showExtLinkModal && <ExternalLinkModal {...extLinkModalData} />}
    </>
  );
};

export default FaqAccordion;

FaqAccordion.defaultProps = {
  id: "faq-accordion", // the id should be name-spaced with the page or category, e.g. "home-loans-faq-accordion"
  isBlog: false, // handle if we use section-padding or extra
  sectionClass: "section-padding", // default for most, override only if no section padding is desired
  containerClass: "container", // default for most, override only if no section container is desired
  pageTitle: null, // Page title (always H1)
  title: "Frequently Asked Questions", // default for most, override only if necessary
  titleClass: "h2",
  seeMoreFaqsLink: null, // the relative page URL to the "See More FAQs" page.
  seeMoreFaqsId: "see-more-faqs-link", // the id should be name-spaced with the page or category, e.g. "home-loans-see-more-faqs-link"
  seeMoreFaqsText: "See More FAQs", // default for most, override only if necessary
  seeMoreFaqsIcon: "arrow-right",
  showContactBar: true,
  faqCategoryNames: [], // Array of Strings; must match (case sensitive) faq_categories.CategoryName in Strapi
  faqData: null // Object { sortedFaqs: [], categoryFootnotes: [], schema: {} (not used) } required if faqCategoryNames is an empty array
};

FaqAccordion.propTypes = {
  id: PropTypes.string,
  isBlog: PropTypes.bool,
  sectionClass: PropTypes.string,
  containerClass: PropTypes.string,
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  seeMoreFaqsLink: PropTypes.string,
  seeMoreFaqsId: PropTypes.string,
  seeMoreFaqsText: PropTypes.string,
  showContactBar: PropTypes.bool,
  faqCategoryNames: PropTypes.array.isRequired,
  sortedFaqs: PropTypes.object
};
